<template>
  <section>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <img
            :src="getFrontEndSkinLogo()"
            :alt="getFrontEndSkinName() + ' logo'"
            class="is-pulled-right"
            style="max-height: 50px"
          >

          <h1 class="title">
            Add a Google Data Studio connector for
          </h1>
          <h2 class="subtitle">
            <i :class="'serviceicon-' + connector.icon" /> {{ connector.name }}
          </h2>
        </div>
      </div>
    </section>

    <section>
      <BaseAuth
        v-if="isReady"
        :is-public="true"
        :internal-connector-id="Number(connectorId)"
        :auth-u-r-i="authURI"
        :gds-redirect-uri="gdsRedirectUri"
        :credentials="credentials"
      />
    </section>
  </section>
</template>

<script>
import Vue from 'vue';
import AuthAPI from '../api/modules/Auth';
import BaseAuth from './auth/BaseAuth';

export default {
  name: 'OAuthPublic',
  components: {
    BaseAuth
  },
  data() {
    return {
      connector: {
        name: '',
        icon: '',
        url: '',
      },
      connectorId: 0,
      authURI: '',
      credentials: [],
      gdsRedirectUri: '',
      isReady: false,
    };
  },
  created() {
    this.connectorId = Number(this.$route.query.integration_type);
    this.setAppLoadingState(true);
    this.getConnectionDetails();
  },
  methods: {
    getConnectionDetails() {
      AuthAPI.authExternally({
        integration_type: this.connectorId,
        masterEmail: this.$route.query.masterEmail,
        client_id: this.$route.query.client_id,
        response_type: this.$route.query.response_type,
        redirect_uri: this.$route.query.redirect_uri,
        state: this.$route.query.state,
        business_unit: this.getFrontEndSkinBusinessUnit(),
      }).then((res) => {
        this.authURI = res.data.authUri;
        this.gdsRedirectUri = res.data.gdsRedirectUri;

        const formattedCredentials = [];
        for (const credential of res.data.credentials || []) {
          formattedCredentials.push(JSON.parse(credential));
        }
        this.credentials = formattedCredentials;

        this.isReady = true;
        this.setAppLoadingState(false);
        // this is public so the user might not be logged in
        Vue.set(this, 'connector', this.getConnectorById(this.connectorId, true));
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Error whilst trying to link data source',
          position: 'is-bottom',
          type: 'is-danger',
        });
      });
    },
  },
};
</script>
